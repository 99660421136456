import React from 'react';
import { MagazinePage } from '../components/MagazinePage';

export function NotesFromFamilies({ pageNumber }) {
  return (
    <MagazinePage pageNumber={pageNumber}>
      <div className="relative px-6 pt-2 pb-6 font-serif">
        {/* Decorative background */}
        <div className="absolute top-0 left-0 w-full h-full opacity-10 pointer-events-none">
          <div className="absolute top-0 right-0 w-24 h-24 bg-orange-500 rounded-full transform translate-x-1/2 -translate-y-1/2" />
          <div className="absolute bottom-0 left-0 w-32 h-32 bg-yellow-400 transform -translate-x-1/4 translate-y-1/4 rotate-45" />
        </div>

        {/* Main content */}
        <div className="relative">
          <div className="relative border-b-2 border-black pb-4 mb-6">
            <div className="absolute -left-6 top-0 bottom-0 w-1 bg-orange-500" />
            <h2 className="text-4xl font-bold tracking-tight">Notes from the Families</h2>
          </div>

          <div className="prose max-w-none space-y-12">
            <section>
              <div className="text-center mb-8">
                <span className="text-4xl">🕉️</span>
                <h3 className="text-2xl font-bold mt-4">From Shreya's Family</h3>
              </div>
              <p className="text-base leading-relaxed">
                With blessings from the late M.R. Parthasarathy and the late Neelakantan Shankar, 
                Sridevi and Rajalakshmi join their children, Surekha (Kamli) and Venky (Poppy) 
                and their grandchildren, Sujay, Sara and Samita (Sammy) to invite you to celebrate 
                the wedding of Shreya with Preetum. We are delighted to honor their unconditional 
                acceptance and request the pleasure of your company to bless the couple.
              </p>
            </section>

            <section>
              <div className="text-center mb-8">
                <h3 className="text-2xl font-bold">From Preetum's Family</h3>
              </div>
              <p className="text-base leading-relaxed italic text-gray-600">
                [Message from Preetum's family to be added]
              </p>
            </section>
          </div>
        </div>
      </div>
    </MagazinePage>
  );
} 