import React from 'react';

export function MagazineLayout({ children, navigation }) {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200">
      <div className="absolute inset-0 bg-white/40" />
      
      <div className="relative z-10 min-h-screen flex flex-col">
        <main className="flex-1 flex flex-col items-center justify-center p-4">
          {/* Magazine container */}
          <div className="w-full h-[calc(100vh-6rem)] max-w-[600px] mx-auto">
            <div className="relative bg-white shadow-xl h-full">
              {children}
            </div>
          </div>
          
          <div className="h-16 flex items-center">
            {navigation}
          </div>
        </main>
      </div>
    </div>
  );
} 