import React from 'react';
import { MagazinePage } from '../components/MagazinePage';

export function LetterFromAuthors({ pageNumber }) {
  return (
    <MagazinePage pageNumber={pageNumber}>
      <div className="relative px-6 pt-2 pb-6 font-serif">
        {/* Decorative background */}
        <div className="absolute top-0 left-0 w-full h-full opacity-10 pointer-events-none">
          <div className="absolute top-0 right-0 w-24 h-24 bg-blue-500 rounded-full transform translate-x-1/2 -translate-y-1/2" />
          <div className="absolute bottom-0 left-0 w-32 h-32 bg-yellow-400 transform -translate-x-1/4 translate-y-1/4 rotate-45" />
        </div>

        {/* Main content */}
        <div className="relative">
          <div className="relative border-b-2 border-black pb-4 mb-6">
            <div className="absolute -left-6 top-0 bottom-0 w-1 bg-blue-500" />
            <h2 className="text-4xl font-bold tracking-tight">Letter from Shreya & Preetum</h2>
          </div>

          {/* Wedding photo with responsive styling */}
          <img 
            src="/wedding.png"
            alt="Shreya and Preetum's wedding"
            className="float-right ml-4 mb-4 w-[140px] sm:w-[200px] h-auto"
            style={{
              WebkitFilter: 'url("#outline")',
              filter: 'url("#outline")',
            }}
          />
          
          {/* SVG filter for outline */}
          <svg width="0" height="0" style={{ position: 'absolute' }}>
            <defs>
              <filter id="outline">
                <feGaussianBlur in="SourceAlpha" stdDeviation="2" result="blur"/>
                <feOffset dx="2" dy="2" in="blur" result="offsetBlur"/>
                <feFlood floodColor="rgba(0,0,0,0.3)" result="shadowColor"/>
                <feComposite in="shadowColor" in2="offsetBlur" operator="in" result="shadow"/>
                <feMerge>
                  <feMergeNode in="shadow"/>
                  <feMergeNode in="SourceGraphic"/>
                </feMerge>
              </filter>
            </defs>
          </svg>

          <div className="prose max-w-none">
            <p className="text-base leading-relaxed">
              Dear friends and family,
            </p>
            <p className="mt-4 text-base leading-relaxed">
              On April 30, 2024, in a private ceremony at San Mateo City Hall, 
              we submitted our initial manuscript and became a married couple. Now, we're thrilled 
              to announce our upcoming celebration with our extended family and friends 
              this summer.
            </p>
            <p className="mt-4 text-base leading-relaxed">
              While our initial ceremony was intimate and meaningful, we're excited to 
              celebrate with all of you on <span className="font-bold text-blue-500">June 29, 2025</span>. We're looking forward to making 
              it camera-ready with our wonderful community of family and friends by our 
              side. We feel incredibly blessed to have all of you in our lives, and we 
              can't imagine this milestone without you there.
            </p>
            <p className="mt-6 text-base">
              With gratitude and joy,
            </p>
            <p className="mt-2 font-serif text-base">
              Shreya & Preetum
            </p>
          </div>
        </div>
      </div>
    </MagazinePage>
  );
} 