import React from 'react';
import { MagazinePage } from '../components/MagazinePage';

export function HowWeMet({ pageNumber }) {
  return (
    <MagazinePage pageNumber={pageNumber}>
      <div className="relative px-6 pt-2 pb-6 font-serif">
        {/* Decorative background */}
        <div className="absolute top-0 left-0 w-full h-full opacity-10 pointer-events-none">
          <div className="absolute top-0 right-0 w-24 h-24 bg-pink-500 rounded-full transform translate-x-1/2 -translate-y-1/2" />
          <div className="absolute bottom-0 left-0 w-32 h-32 bg-purple-400 transform -translate-x-1/4 translate-y-1/4 rotate-45" />
        </div>

        <div className="relative border-b-2 border-black pb-4 mb-6">
          <div className="absolute -left-6 top-0 bottom-0 w-1 bg-pink-500" />
          <h2 className="text-4xl font-bold tracking-tight">How We Met</h2>
        </div>

        <div className="prose max-w-none">
          <p className="text-base leading-relaxed">
            Our story began on Twitter (back when it was Twitter). Shreya found 
            Preetum's tweets hilarious and followed him. When she tried to slide 
            into his DMs about some gossip, he politely declined - he wasn't big 
            on virtual chats.
          </p>

          <p className="text-base leading-relaxed mt-4">
            Months later, while visiting the Bay Area for a conference, Preetum 
            suggested meeting in person. They had dinner at Gather in Berkeley, 
            and despite the restaurant's mediocrity, they've been inseparable 
            ever since.
          </p>

          <p className="text-base leading-relaxed mt-4">
            While they've sworn off academic collaborations (and plan to keep it 
            that way), they've found their perfect collaboration in life: raising 
            their puppy together in Berkeley, where they enjoy walks through the Marina
            and cozy evenings at home.
          </p>
          
        </div>
      </div>
    </MagazinePage>
  );
} 