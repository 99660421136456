import React from 'react';
import { MagazinePage } from '../components/MagazinePage';

export function RSVP({ pageNumber }) {
  return (
    <MagazinePage pageNumber={pageNumber}>
      <div className="relative px-6 pt-2 pb-6 font-serif">
        {/* Decorative background */}
        <div className="absolute top-0 left-0 w-full h-full opacity-10 pointer-events-none">
          <div className="absolute top-0 right-0 w-24 h-24 bg-blue-500 rounded-full transform translate-x-1/2 -translate-y-1/2" />
          <div className="absolute bottom-0 left-0 w-32 h-32 bg-yellow-400 transform -translate-x-1/4 translate-y-1/4 rotate-45" />
        </div>

        {/* Main content */}
        <div className="relative">
          <div className="relative border-b-2 border-black pb-4 mb-6">
            <div className="absolute -left-6 top-0 bottom-0 w-1 bg-blue-500" />
            <h2 className="text-4xl font-bold tracking-tight">RSVP</h2>
          </div>

          <div className="prose max-w-none">
            <p className="text-base leading-relaxed">
              Please RSVP by March 1st, 2025 at:
            </p>
            
            <div className="mt-4 bg-gray-50 p-6 rounded-lg">
              <a 
                href="https://withjoy.com/shreya-and-preetum/rsvp" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800 block mb-2"
              >
                withjoy.com/shreya-and-preetum/rsvp
              </a>
              <p className="text-sm text-gray-600 mb-1">Password: shankiran2025</p>
            </div>

            <p className="mt-4 text-base leading-relaxed italic">
              Your timely response will help us plan for your attendance. We look forward to celebrating with you!
            </p>
          </div>
        </div>
      </div>
    </MagazinePage>
  );
} 