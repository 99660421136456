import React from 'react';
import { MagazinePage } from '../components/MagazinePage';

export function CoverPage({ pageNumber }) {
  return (
    <MagazinePage background="navy" pageNumber={pageNumber}>
      <div className="absolute inset-0 bg-[#1a1a4d]">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-900/30 to-purple-900/30" />
      </div>
      
      <div className="absolute inset-0 flex flex-col p-4 sm:p-6 md:p-8 text-white">
        <div className="flex justify-between text-xs sm:text-sm mb-4 sm:mb-8">
          <a href="https://shankiran.org" className="hover:underline">SHANKIRAN.ORG</a>
          <span>JUNE 2025 VOL.∞ NO.1</span>
        </div>
        <div className="mx-auto text-center max-w-3xl mb-8 sm:mb-12">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-2 tracking-widest">
            COMMUNICATIONS
          </h1>
          <div className="flex items-start justify-center gap-2">
            <div className="text-sm tracking-widest mt-1">
              OF THE
            </div>
            <h2 className="text-4xl sm:text-5xl font-bold tracking-widest">
              SHANKIRANS
            </h2>
          </div>
        </div>

        <div className="flex-1 flex flex-col justify-end">
          <div className="space-y-3 text-lg font-serif">
            <h2 className="text-3xl sm:text-4xl font-display font-bold text-yellow-400 leading-tight tracking-wide">
              Shreya & Preetum:<br />
              The Camera-Ready
            </h2>
            <div className="flex items-center gap-2">
              <span className="text-yellow-400">{'>'}</span>
              <span>A Celebration in Dallas, Texas</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-yellow-400">{'>'}</span>
              <span>Tying the knot on June 29, 2025</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-yellow-400">{'>'}</span>
              <span>RSVP at <a href="https://withjoy.com/shreya-and-preetum/rsvp" className="underline">withjoy.com</a> (password: shankiran2025)</span>
            </div>
          </div>
        </div>
      </div>
    </MagazinePage>
  );
} 