import React from 'react';
import { MagazinePage } from '../components/MagazinePage';

export function EventSchedule({ pageNumber }) {
  return (
    <MagazinePage pageNumber={pageNumber}>
      <div className="relative px-6 pt-2 pb-6 font-serif">
        <div className="absolute top-0 left-0 w-full h-full opacity-10 pointer-events-none">
          <div className="absolute top-0 right-0 w-24 h-24 bg-purple-500 rounded-full transform translate-x-1/2 -translate-y-1/2" />
          <div className="absolute bottom-0 left-0 w-32 h-32 bg-green-400 transform -translate-x-1/4 translate-y-1/4 rotate-45" />
          <div className="absolute top-1/2 right-1/4 w-16 h-16 border-4 border-purple-300 rounded-full" />
        </div>

        <div className="relative border-b-2 border-black pb-4 mb-8">
          <div className="absolute -left-6 top-0 bottom-0 w-1 bg-green-500" />
          <h2 className="text-4xl font-bold tracking-tight">Event Schedule</h2>
        </div>

        <div className="space-y-12">
          <div className="space-y-12">
            <EventItem 
              title="Wedding Ceremony"
              date="Sunday, June 29, 2025"
              time="9:00 AM - 11:00 AM"
              venue="Atithi Hall"
              description='A "modern" Tamil wedding ceremony, followed by lunch.'
              attire='Traditional Indian attire preferred. Options include sarees, lehengas, anarkalis, kurta pajamas, or sherwanis. Bright colors encouraged.'
            />

            <EventItem 
              title="Reception & Sangeet"
              date="Sunday, June 29, 2025"
              time="6:00 PM onwards"
              venue="Marriott Dallas/Plano Legacy Town Center"
              description="Evening celebration with dinner, dancing, and performances."
              attire="Indian formal or Western cocktail attire. Options include sarees, lehengas, formal suits, indo-western outfits, cocktail dresses, or formal kurtas."
            />
          </div>

          <div className="mt-8 pt-6 border-t border-gray-300">
            <h3 className="text-lg font-bold mb-4 uppercase tracking-widest">Venue Details</h3>
            <div className="space-y-4">
              <VenueInfo 
                name="Atithi Hall"
                address="9060 Independence Pkwy, Plano, TX 75025"
                mapLink="https://maps.app.goo.gl/8CGh7kjoHNVV65Ba7"
              />
              <VenueInfo 
                name="Marriott Dallas/Plano"
                address="7121 Bishop Rd, Plano, TX 75024"
                mapLink="https://maps.app.goo.gl/buawZCYVwgrR2Gme6"
              />
            </div>
          </div>
        </div>
      </div>
    </MagazinePage>
  );
}

function EventItem({ title, date, time, venue, description, attire }) {
  return (
    <div>      
      <h3 className="text-2xl font-bold mb-4 leading-tight text-purple-900">
        {title}
      </h3>
      
      <div className="text-lg leading-relaxed">
        <p className="mb-4">{description}</p>
        
        <div className="font-medium">
          <p className="mb-1">
            <span className="text-purple-800">Date:</span> {date}
          </p>
          <p className="mb-1">
            <span className="text-purple-800">Time:</span> {time}
          </p>
          <p className="mb-4">
            <span className="text-purple-800">Venue:</span> {venue}
          </p>
        </div>

        <div className="mt-6 border-l-2 border-purple-200 pl-4">
          <p className="text-purple-900 uppercase tracking-wider text-sm mb-2 font-semibold">What to Wear</p>
          <p>{attire}</p>
        </div>
      </div>
    </div>
  );
}

function VenueInfo({ name, address, mapLink }) {
  return (
    <div className="bg-gray-50 p-3 rounded-lg">
      <h4 className="font-bold mb-1">{name}</h4>
      <p className="text-sm text-gray-600 mb-1">{address}</p>
      <a 
        href={mapLink}
        target="_blank"
        rel="noopener noreferrer"
        className="text-sm text-purple-700 hover:text-purple-900 hover:underline"
      >
        View on Maps →
      </a>
    </div>
  );
} 