import React from 'react';
import { MagazinePage } from '../components/MagazinePage';

export function TechnicalAppendix({ pageNumber }) {
  return (
    <MagazinePage pageNumber={pageNumber}>
      <div className="relative px-6 pt-2 pb-6 font-serif">
        <div className="absolute top-0 left-0 w-full h-full opacity-10 pointer-events-none">
          <div className="absolute top-0 right-0 w-24 h-24 bg-green-500 rounded-full transform translate-x-1/2 -translate-y-1/2" />
          <div className="absolute bottom-0 left-0 w-32 h-32 bg-blue-400 transform -translate-x-1/4 translate-y-1/4 rotate-45" />
          <div className="absolute top-1/2 right-1/4 w-16 h-16 border-4 border-green-300 rounded-full" />
        </div>

        <div className="relative border-b-2 border-black pb-4 mb-8">
          <div className="absolute -left-6 top-0 bottom-0 w-1 bg-green-500" />
          <h2 className="text-4xl font-bold tracking-tight">Technical Details</h2>
        </div>

        <div className="space-y-12">
          {/* Main content */}
          <div className="space-y-12">
            <DetailSection
              title="Accommodation"
              icon="hotel"
              details={{
                'Hotel': {
                  text: 'Marriott Dallas/Plano',
                  subtext: '7121 Bishop Rd, Plano, TX 75024',
                  link: 'https://www.marriott.com/en-us/hotels/dalpt-dallas-plano-marriott-at-legacy-town-center/'
                },
                'Block Code': 'SHANKIRAN2025',
                'Cut-off Date': 'May 29, 2025'
              }}
              description="We've arranged special rates for our guests. Please book before the cut-off date to secure the discounted price."
            />

            <DetailSection
              title="Transportation"
              icon="plane"
              details={{
                'DFW Airport': {
                  text: '25 miles',
                  link: 'https://www.dfwairport.com/'
                },
                'Love Field': {
                  text: '20 miles',
                  link: 'https://www.dallas-lovefield.com/'
                }
              }}
              extraInfo="Rideshare available from both airports"
              description="Both airports offer convenient access to the venue. We recommend scheduling your rides in advance during peak hours."
            />

            <DetailSection
              title="Local Attractions"
              icon="map-marked-alt"
              details={{
                'Legacy West': {
                  text: 'Shopping & Dining',
                  link: 'https://legacywest.com/'
                },
                'Shops at Legacy': {
                  text: 'Boutiques & Restaurants',
                  link: 'https://www.shopsatlegacy.com/'
                },
                'Legacy Trails': {
                  text: 'Walking & Biking'
                }
              }}
              description="The venue is surrounded by upscale shopping and dining options. Perfect for exploring during your downtime."
            />
          </div>

          {/* Quick Links section */}
          <div className="mt-8 pt-6 border-t border-gray-300">
            <h3 className="text-lg font-bold mb-4 uppercase tracking-widest">Quick Links</h3>
            <div className="space-y-4">
              <QuickLink 
                name="Book Hotel"
                text="Code: SHANKIRAN2025"
                link="https://www.marriott.com/en-us/hotels/dalpt-dallas-plano-marriott-at-legacy-town-center/"
              />
              <QuickLink 
                name="Area Guide"
                text="Legacy West Directory"
                link="https://legacywest.com/"
              />
            </div>
          </div>
        </div>
      </div>
    </MagazinePage>
  );
}

function DetailSection({ title, icon, details, extraInfo, description }) {
  return (
    <div>      
      <h3 className="text-2xl font-bold mb-4 leading-tight text-purple-900">
        {title}
      </h3>
      
      {description && (
        <p className="text-lg leading-relaxed text-gray-600 mb-4">{description}</p>
      )}
      
      <div className="text-lg leading-relaxed">
        {Object.entries(details).map(([key, value]) => (
          <div key={key} className="mb-1">
            {typeof value === 'object' ? (
              <>
                <p className="mb-1">
                  <span className="font-medium">{key}:</span>{' '}
                  <a 
                    href={value.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:text-blue-800 hover:underline"
                  >
                    {value.text}
                  </a>
                </p>
                {value.subtext && <p className="text-sm text-gray-600 mb-2">{value.subtext}</p>}
              </>
            ) : (
              <p className="mb-1">
                <span className="font-medium">{key}:</span>{' '}
                <span className="text-gray-700">{value}</span>
              </p>
            )}
          </div>
        ))}
        
        {extraInfo && (
          <div className="mt-6 border-l-2 border-purple-200 pl-4">
            <p className="text-purple-900 uppercase tracking-wider text-sm mb-2 font-semibold">Getting Around</p>
            <p className="text-gray-700">{extraInfo}</p>
          </div>
        )}
      </div>
    </div>
  );
}

function QuickLink({ name, text, link }) {
  return (
    <div className="bg-gray-50 p-3 rounded-lg">
      <h4 className="font-bold mb-1">{name}</h4>
      <p className="text-sm text-gray-600 mb-1">{text}</p>
      <a 
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="text-sm text-purple-700 hover:text-purple-900 hover:underline"
      >
        Visit Website →
      </a>
    </div>
  );
} 