import React from 'react';

export function MagazinePage({ children, background = 'white', pageNumber }) {
  return (
    <div 
      className="relative w-full h-full overflow-y-auto overflow-x-hidden flex flex-col"
      style={{ backgroundColor: background }}
    >
      {/* Header - Magazine style running header */}
      <div className="py-2 px-6">
        <div className="text-xs uppercase tracking-[0.2em] text-gray-400 border-b border-gray-200 pb-2">
          <span>Our Wedding Story</span>
        </div>
      </div>

      {/* Main Content */}
      <div className="px-6 py-4 flex-grow">
        {children}
      </div>

      {/* Footer - Magazine style running footer */}
      <div className="py-2 px-6">
        <div className="text-xs uppercase tracking-[0.2em] text-gray-400 border-t border-gray-200 pt-2 flex justify-between">
          <span>June 2025</span>
          <span>{pageNumber}</span>
        </div>
      </div>
    </div>
  );
} 