import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MagazinePage } from '../components/MagazinePage';

export function TableOfContents({ pageNumber }) {
  const navigate = useNavigate();

  const contents = [
    {
      pageNum: "03",
      title: "Letter from Shreya & Preetum",
      subtitle: "A note from the happy couple",
      color: "blue",
      path: "/letter"
    },
    {
      pageNum: "04",
      title: "How We Met",
      subtitle: "The story of our beginning",
      color: "red",
      path: "/how-we-met"
    },
    {
      pageNum: "05",
      title: "Event Schedule",
      subtitle: "Ceremony & Reception Details",
      color: "yellow",
      path: "/schedule"
    },
    {
      pageNum: "06",
      title: "Technical Details",
      subtitle: "Hotels, Transportation & Local Activities",
      color: "green",
      path: "/technical"
    },
    {
      pageNum: "07",
      title: "RSVP",
      subtitle: "Please RSVP by March 1st, 2025",
      color: "purple",
      path: "/rsvp"
    },
    {
      pageNum: "08",
      title: "Notes from the Families",
      subtitle: "Messages from our loved ones",
      color: "orange",
      path: "/family-notes"
    }
  ];

  return (
    <MagazinePage pageNumber={pageNumber}>
      <div className="relative px-4 pt-2 pb-6 font-serif">
        <div className="absolute top-0 left-0 w-full h-full opacity-10 pointer-events-none">
          <div className="absolute top-0 right-0 w-24 h-24 bg-blue-500 rounded-full transform translate-x-1/2 -translate-y-1/2" />
          <div className="absolute bottom-0 left-0 w-32 h-32 bg-yellow-400 transform -translate-x-1/4 translate-y-1/4 rotate-45" />
          <div className="absolute top-1/2 right-1/4 w-16 h-16 border-4 border-red-500 rounded-full" />
        </div>

        <div className="relative border-b-2 border-black pb-4 mb-6">
          <div className="absolute -left-4 top-0 bottom-0 w-1 bg-blue-500" />
          <h2 className="text-4xl font-bold tracking-tight">Table of Contents</h2>
          <p className="text-gray-600 mt-2 font-mono text-base">June 2025 | Vol.∞ | No. 1</p>
        </div>
        
        <div className="space-y-2">
          {contents.map((item) => (
            <ContentItem 
              key={item.path}
              {...item}
              onClick={() => navigate(item.path)}
            />
          ))}
        </div>
      </div>
    </MagazinePage>
  );
}

function ContentItem({ pageNum, title, subtitle, color, onClick }) {
  const colors = {
    blue: 'bg-blue-500',
    red: 'bg-red-500',
    yellow: 'bg-yellow-400',
    green: 'bg-green-500',
    purple: 'bg-purple-500',
    orange: 'bg-orange-500'
  };

  return (
    <div 
      onClick={onClick}
      className="group flex items-start hover:bg-gray-50 p-2 -mx-2 rounded-lg transition-colors duration-200 cursor-pointer"
    >
      <div className="relative mr-3 w-8 text-right">
        <span className="text-2xl font-mono font-bold">{pageNum}</span>
        <div className={`absolute left-0 top-1/2 -translate-y-1/2 w-1 h-8 ${colors[color]} transform scale-x-0 group-hover:scale-x-100 transition-transform duration-200 origin-left`} />
      </div>

      <div className="flex-1">
        <h3 className="text-xl font-bold tracking-tight">{title}</h3>
        <p className="text-gray-600 text-base font-light">{subtitle}</p>
      </div>

      <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-200">
        <span className="text-xl">→</span>
      </div>
    </div>
  );
} 