import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import './App.css';
import { MagazineLayout } from './components/MagazineLayout';
import { CoverPage } from './pages/CoverPage';
import { TableOfContents } from './pages/TableOfContents';
import { LetterFromAuthors } from './pages/LetterFromAuthors';
import { HowWeMet } from './pages/HowWeMet';
import { EventSchedule } from './pages/EventSchedule';
import { TechnicalAppendix } from './pages/TechnicalAppendix';
import { RSVP } from './pages/RSVP';
import { NotesFromFamilies } from './pages/NotesFromFamilies';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(0);

  const pages = useMemo(() => [
    { path: '/', content: <CoverPage pageNumber={1} />, pageNumber: 1 },
    { path: '/contents', content: <TableOfContents pageNumber={2} />, pageNumber: 2 },
    { path: '/letter', content: <LetterFromAuthors pageNumber={3} />, pageNumber: 3 },
    { path: '/how-we-met', content: <HowWeMet pageNumber={4} />, pageNumber: 4 },
    { path: '/schedule', content: <EventSchedule pageNumber={5} />, pageNumber: 5 },
    { path: '/technical', content: <TechnicalAppendix pageNumber={6} />, pageNumber: 6 },
    { path: '/rsvp', content: <RSVP pageNumber={7} />, pageNumber: 7 },
    { path: '/family-notes', content: <NotesFromFamilies pageNumber={8} />, pageNumber: 8 },
  ], []);

  // Update currentPage when route changes
  useEffect(() => {
    const pageIndex = pages.findIndex(page => page.path === location.pathname);
    if (pageIndex !== -1) {
      setCurrentPage(pageIndex);
    }
  }, [location.pathname, pages]);

  const nextPage = useCallback(() => {
    const nextIndex = Math.min(currentPage + 1, pages.length - 1);
    setCurrentPage(nextIndex);
    navigate(pages[nextIndex].path);
  }, [currentPage, navigate, pages]);

  const prevPage = useCallback(() => {
    const prevIndex = Math.max(currentPage - 1, 0);
    setCurrentPage(prevIndex);
    navigate(pages[prevIndex].path);
  }, [currentPage, navigate, pages]);

  useEffect(() => {
    // Keyboard navigation
    const handleKeyPress = (e) => {
      if (e.key === 'ArrowLeft') {
        prevPage();
      } else if (e.key === 'ArrowRight') {
        nextPage();
      }
    };

    // Touch handling for swipe
    let touchStartX = 0;
    let touchStartY = 0;
    let touchEndX = 0;
    let touchEndY = 0;
    
    const handleTouchStart = (e) => {
      touchStartX = e.touches[0].clientX;
      touchStartY = e.touches[0].clientY;
    };
    
    const handleTouchEnd = (e) => {
      touchEndX = e.changedTouches[0].clientX;
      touchEndY = e.changedTouches[0].clientY;
      handleSwipe();
    };
    
    const handleSwipe = () => {
      const swipeThreshold = 50;
      const horizontalDifference = touchStartX - touchEndX;
      const verticalDifference = Math.abs(touchStartY - touchEndY);
      
      // Only trigger swipe if horizontal movement is greater than vertical
      // and exceeds the minimum threshold
      if (Math.abs(horizontalDifference) < swipeThreshold || 
          Math.abs(horizontalDifference) < verticalDifference) {
        return;
      }
      
      if (horizontalDifference > 0) {
        nextPage();
      } else {
        prevPage();
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [currentPage, nextPage, prevPage]);

  const navigation = (
    <div className="flex items-center gap-8 sm:gap-12 text-sm sm:text-base">
      <button 
        onClick={prevPage}
        disabled={currentPage === 0}
        className="flex items-center gap-2 disabled:opacity-40 disabled:cursor-not-allowed transition-opacity bg-white/90 px-3 py-1.5 rounded-lg shadow-sm"
      >
        <svg 
          className="w-5 h-5" 
          fill="none" 
          stroke="currentColor" 
          viewBox="0 0 24 24"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M15 19l-7-7 7-7" 
          />
        </svg>
        <span className="font-display hidden sm:inline">Previous</span>
      </button>

      <span className="font-display text-sm tracking-wider bg-white/90 px-3 py-1.5 rounded-lg shadow-sm">
        {String(currentPage + 1).padStart(2, '0')} / {String(pages.length).padStart(2, '0')}
      </span>

      <button 
        onClick={nextPage}
        disabled={currentPage === pages.length - 1}
        className="flex items-center gap-2 disabled:opacity-40 disabled:cursor-not-allowed transition-opacity bg-white/90 px-3 py-1.5 rounded-lg shadow-sm"
      >
        <span className="font-display hidden sm:inline">Next</span>
        <svg 
          className="w-5 h-5" 
          fill="none" 
          stroke="currentColor" 
          viewBox="0 0 24 24"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M9 5l7 7-7 7" 
          />
        </svg>
      </button>
    </div>
  );

  return (
    <MagazineLayout navigation={navigation}>
      <Routes>
        {pages.map(({ path, content }) => (
          <Route key={path} path={path} element={content} />
        ))}
      </Routes>
    </MagazineLayout>
  );
}

export default App;
